export const carCollections = [
    {
      id: 1,
      name: "ALTO",
      image: "assets/cars/alto.jpg",
      carInfo: "Outstation charges for Vizag, beyond 50 km, will be ₹500.",
      priceDetails: [
        { id: 1, duration: "1 Day", price: 1499, kms: "200 Kms" },
      ],
      exceed: 'Kilometors limit if exceeds Rs.6/KM'
    },
    {
        id: 2,
        name: "Wagonr Big",
        image: "assets/cars/WagonrBig.jpg",
        carInfo: "Outstation charges for Vizag, beyond 50 km, will be ₹500.",
        priceDetails: [
          { id: 1, duration: "1 Day", price: 1999, kms: "200 Kms" },
        ],
        exceed: 'Kilometors limit if exceeds Rs.6/KM'
      },
      {
        id: 3,
        name: "Swift",
        image: "assets/cars/Swift.jpg",
        carInfo: "Outstation charges for Vizag, beyond 50 km, will be ₹500 extra.",
        priceDetails: [
          { id: 1, duration: "1 Day", price: 2000, kms: "200 Kms" },
        ],
        exceed: 'Kilometors limit if exceeds Rs.6/KM'
      },
      {
        id: 4,
        name: "IGNIS",
        image: "assets/cars/IGNIS.jpg",
        carInfo: "Outstation charges for Vizag, beyond 50 km, will be ₹500 extra.",
        priceDetails: [
          { id: 1, duration: "1 Day", price: 2000, kms: "200 Kms" },
        ],
        exceed: 'Kilometors limit if exceeds Rs.9/KM'
      },
      {
        id: 5,
        name: "Altroz",
        image: "assets/cars/Altroz.jpg",
        carInfo: "Outstation charges for Vizag, beyond 50 km, will be ₹500 extra.",
        priceDetails: [
          { id: 1, duration: "1 Day", price: 2499, kms: "250 Kms" },
        ],
        exceed: 'Kilometors limit if exceeds Rs.9/KM'
      },
      {
        id: 6,
        name: "Baleno",
        image: "assets/cars/Baleno.jpg",
        carInfo: "Outstation charges for Vizag, beyond 50 km, will be ₹500 extra.",
        priceDetails: [
          { id: 1, duration: "1 Day", price: 2499, kms: "250 Kms" },
        ],
        exceed: 'Kilometors limit if exceeds Rs.9/KM'
      },
      {
        id: 7,
        name: "Swift Dzire",
        image: "assets/cars/swift-dzire.webp",
        carInfo: "Outstation charges for Vizag, beyond 50 km, will be ₹500 extra.",
        priceDetails: [
          { id: 1, duration: "1 Day", price: 2499, kms: "250 Kms" },
        ],
        exceed: 'Kilometors limit if exceeds Rs.9/KM'
      },
      {
        id: 8,
        name: "Ertiga",
        image: "assets/cars/Ertiga1.jpg",
        carInfo: "Outstation charges for Vizag, beyond 50 km, will be ₹500 extra.",
        priceDetails: [
          { id: 1, duration: "1 Day", price: 2999, kms: "300 Kms" },
        ],
        exceed: 'Kilometors limit if exceeds Rs.8/KM'
      },
  ]
  
  