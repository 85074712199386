import {
  IonContent,
  IonIcon,
  IonPage,
} from "@ionic/react";
import Footer from "./Footer";
import "./Layout.css";
import { Logo } from "./Logo";

const Layout: React.FC = ({ children }) => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <div
          className="ionic-header"
        >
          <div className="top-header">
            <div><img alt="chaloride logo" width={'35px'} src="assets/icon/favicon.png" /></div>
            <div><IonIcon icon="logo-whatsapp" title="chaloride whats app" onClick={() => window.location.href = "https://wa.me/+919577677779?text=Hi chaloride, Im looking for hire rental"} /></div>
            <div><IonIcon icon="navigate" onClick={() => window.location.href = "https://maps.app.goo.gl/RieW9f4FsupAxy1r7"} title="chaloride location" /></div>
            <div style={{ borderRight: '0' }} onClick={() => window.location.href = "tel:+919577677779"}><IonIcon icon="call" title=" call t0 +91 9577677779" />&nbsp;<span style={{ fontSize: '14px' }}>+91 9577677779</span></div>
          </div>
          <Logo style={{
            borderRadius: '5px',
            width: '210px',
            padding: '5px',
            position: 'relative',
            top: '-50px'
          }} />
        </div>
        {children}
        <div className="bikes-display-section">
          <h2 className="section-caption">Our Location</h2>
          <iframe title="chaloride" style={{ width: "100%" }} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15201.623307838228!2d83.2987211!3d17.7255073!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a3943f31a1c48a7%3A0x2baec27b15f0e855!2sChalo%20Ride%20-%20Bikes%2C%20Cars%20Rentals%20%7C%20chaloride.in%20%7C%20Bike%20rental%20in%20vizag%20%7C%20Vizag!5e0!3m2!1sen!2sin!4v1724067087516!5m2!1sen!2sin" width="600" height="450" loading="lazy"></iframe>
        </div>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default Layout;
