export const hotelCollections = [
    {
      id: 1,
      type: true,
      name: "Standard Room in Hotel",
      location: "Near By Railway Station, Vizag",
      image: "assets/cars/v-hotel.jpg",
      priceDetails: [
        { id: 1, duration: "1 Day", price: 999, kms: "1 Day" },
      ],
      exceed: 'Reach out us for Hotels booking, we are here to help you better and best deal ever!'
    },
    {
      id: 2,
      type: true,
      name: "Classic Room in Hotel",
      location: "Near By Railway Station, Vizag",
      image: "assets/cars/The-best-hotels-near-Delhi-airport.webp",
      priceDetails: [
        { id: 1, duration: "1 Day", price: 1499, kms: "1 Day" },
      ],
      exceed: 'Reach out us for Hotels booking, we are here to help you better and best deal ever!'
    },
    {
      id: 3,
      type: true,
      name: "Fortune/Luxary Room in Hotel",
      location: "Near By Railway Station, Vizag",
      image: "assets/cars/photo5jpg.jpg",
      priceDetails: [
        { id: 1, duration: "1 Day", price: 1999, kms: "1 Day" },
      ],
      exceed: 'Reach out us for Hotels booking, we are here to help you better and best deal ever!'
    },


    {
      id: 4,
      type: true,
      name: "Standard Room in Hotel",
      location: "Near By Bus Station, Vizag",
      image: "assets/cars/v-hotel.jpg",
      priceDetails: [
        { id: 1, duration: "1 Day", price: 999, kms: "1 Day" },
      ],
      exceed: 'Reach out us for Hotels booking, we are here to help you better and best deal ever!'
    },
    {
      id: 5,
      type: true,
      name: "Classic Room in Hotel",
      location: "Near By Bus Station, Vizag",
      image: "assets/cars/The-best-hotels-near-Delhi-airport.webp",
      priceDetails: [
        { id: 1, duration: "1 Day", price: 1499, kms: "1 Day" },
      ],
      exceed: 'Reach out us for Hotels booking, we are here to help you better and best deal ever!'
    },
    {
      id: 6,
      type: true,
      name: "Fortune/Luxary Room in Hotel",
      location: "Near By Bus Station, Vizag",
      image: "assets/cars/photo5jpg.jpg",
      priceDetails: [
        { id: 1, duration: "1 Day", price: 1999, kms: "1 Day" },
      ],
      exceed: 'Reach out us for Hotels booking, we are here to help you better and best deal ever!'
    },


    {
      id: 9,
      type: true,
      name: "Standard Room in Hotel",
      location: "Near By R.K Beach, Vizag",
      image: "assets/cars/beach3.jpg",
      priceDetails: [
        { id: 1, duration: "1 Day", price: 999, kms: "1 Day" },
      ],
      exceed: 'Reach out us for Hotels booking, we are here to help you better and best deal ever!'
    },
    {
      id: 7,
      type: true,
      name: "Classic Room in Hotel",
      location: "Near By R.K Beach, Vizag",
      image: "assets/cars/beach2.jpg",
      priceDetails: [
        { id: 1, duration: "1 Day", price: 1499, kms: "1 Day" },
      ],
      exceed: 'Reach out us for Hotels booking, we are here to help you better and best deal ever!'
    },
    {
      id: 8,
      type: true,
      name: "Fortune/Luxary Room in Hotel",
      location: "Near By R.K Beach, Vizag",
      image: "assets/cars/beach1.jpg",
      priceDetails: [
        { id: 1, duration: "1 Day", price: 1999, kms: "1 Day" },
      ],
      exceed: 'Reach out us for Hotels booking, we are here to help you better and best deal ever!'
    },
  ]

  
  