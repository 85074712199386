export const travelCollections = [
  {
    id: 1,
    name: "5 seater dzire maruti",
    image: "assets/cars/dzire.png",
    location: "Vizag",
    mileage: "100Kms/10Hr",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 2500, kms: "100Kms" },
    ],
  },
  {
    id: 2,
    name: "5 seater dzire maruti",
    image: "assets/cars/aruku-s.jpg",
    location: "Aruku",
    mileage: "12Hrs",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 4500, kms: "12Hrs" },
    ],
  },

  {
    id: 3,
    name: "7 seater Maruti Ertiga",
    image: "assets/cars/eartika-7.jpg",
    location: "Vizag",
    mileage: "100Kms/10Hr",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 3500, kms: "100Kms" },
    ],
  },

  {
    id: 4,
    name: "7 seater Maruti Ertiga",
    image: "assets/cars/eartika-7-2.jpg",
    location: "Aruku",
    mileage: "12Hrs",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 6000, kms: "12Hrs" },
    ],
  },

  {
    id: 5,
    name: "7 seater Innova",
    image: "assets/cars/Innova-v.jpg",
    location: "Vizag",
    mileage: "100Kms/10Hr",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 4000, kms: "100Kms" },
    ],
  },

  {
    id: 6,
    name: "7 seater Innova",
    image: "assets/cars/Innova-a.jpg",
    location: "Aruku",
    mileage: "12Hrs",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 6500, kms: "12Hrs" },
    ],
  },


  {
    id: 7,
    name: "Tata Winger Tourist/Staff 12 Seater",
    image: "assets/cars/12seater-v.jpg",
    location: "Vizag",
    mileage: "100Kms/10Hr",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 6500, kms: "100Kms" },
    ],
  },

  {
    id: 8,
    name: "Tata Winger Tourist/Staff 12 Seater",
    image: "assets/cars/12seata.jpg",
    location: "Aruku",
    mileage: "12Hrs",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 9000, kms: "12Hrs" },
    ],
  },
]



  