import { IonInput, IonItem, IonLabel } from "@ionic/react";
import ExploreContainer from "../components/ExploreContainer";
import Header from "../components/Layout";

const UploadBikes: React.FC = () => {

    return (
        <Header>
            <ExploreContainer>
                <IonItem>
                    <label>Select Model</label>
                    <select>
                        <option>Bikes</option>
                        <option>Cars</option>
                        <option>Hotels</option>
                        <option>Taxis</option>
                    </select>
                </IonItem>
                <IonItem>
                    <IonLabel position="floating">Model</IonLabel>
                    <IonInput type="text"></IonInput>
                </IonItem>
            </ExploreContainer>
        </Header>
    );
};

export default UploadBikes;
